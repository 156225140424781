import React from 'react'
import { Helmet } from 'react-helmet'

export default function Blank() {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  )
}
